@import "../../assets/styles/foundations.scss";

.mainContainer {
  height: 100%;
  width: 100%;
  text-align: initial;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .hidden-button {
    display: flex;
    border: none;
    background-color: transparent;
    line-height: 0;
    cursor: pointer;
  }

  .hidden-button:focus {
    outline: 0;
  }
}
