@import "../../../../assets/styles/foundations.scss";

.container {
  position: relative;
  width: 20.25rem; //new

  & > :first-child {
    margin-left: $px1;

    font-family: "Roboto-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0015em;
    color: $neutral-900;
  }

  & > :last-child {
    position: absolute;
    left: 15.375rem;
  }
}
