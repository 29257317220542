@import "../../../../assets/styles/foundations.scss";

.container {
  height: 100vh;
  display: grid;
  grid-template: minmax($px48, 8.5rem) //new
    minmax(33.0625rem, 66.75rem) //new
    minmax($px48, 8.5rem) / //new
    minmax(6.5rem, 11.75rem) //new
    minmax(74.875rem, 141.25rem) //new
    minmax($px64, 7rem);

  animation: $showAnimation;
}

.contentContainer {
  width: 100%;
  height: 100%;
  grid-area: 2/2/3/3;
  display: grid;
  grid-template-columns: minmax(27.25rem, 41.75rem) //new
    minmax($px44, 8.5rem) //new
    minmax(44.875rem, 91rem); //new
  grid-template-rows: 100%;

  &__sideContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // -webkit-user-select: none; /* Safari */
    // -moz-user-select: none; /* Firefox */
    // -ms-user-select: none; /* IE10+/Edge */
    // user-select: none; /* Standard */

    & > :first-child {
      margin-bottom: $px28;
    }

    & > :last-child {
      margin-top: $px64;
    }

    h1 {
      font-family: "Roboto-Medium";
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: -0.005em;
      color: $neutral-900;
    }

    p {
      font-family: "Roboto-Regular";
      font-size: 24px;
      line-height: 32px;
      color: $neutral-800;
    }
  }

  &__imgContainer {
    height: 100%;
    grid-area: 1/3/2/4;
    display: flex;
    flex-wrap: wrap;
    div {
      height: 100%;
      width: 100%;
    }

    img {
      margin: auto;

      max-width: 100%;
      max-height: 100%;
    }
  }
}

.containerOneContent {
  grid-area: 1/1/4/4;
  grid-template-columns:
    1fr
    minmax(27.25rem, 41.75rem) //new
    1fr;
}

.oneContentArea {
  grid-area: 1/2/2/3;
}

@media screen and (max-width: 2442px) {
  .contentContainer__sideContainer h1 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.0025em;
  }

  .contentContainer__sideContainer p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0015em;
  }
}
