.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  /*background: #E5E5E5;*/
  background: white;
  margin: 0;
  padding: 0;
}
