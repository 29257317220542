@import "../../../../assets/styles/foundations.scss";

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Golos-Bold";
    font-size: 48px;
    line-height: 74px;
    text-align: center;
    letter-spacing: 1px;
    color: $neutral-900;
  }

  p {
    margin-top: 8px;
    padding-left: $px4;

    font-family: "Golos-Regular";
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: $neutral-800;
  }
}
