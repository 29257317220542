@import "../../../../../../assets/styles/foundations.scss";

.container {
  display: flex;
  align-items: center;
  height: $px40;

  button {
    height: $px40;
    cursor: pointer;
    border-radius: $radius;

    i {
      display: block;
    }

    path {
      transition: fill $transitionS;
    }

    transition: background $transitionS;

    &:hover {
      path {
        fill: $neutral-900;
      }

      background: $neutral-200;
    }
  }

  &__paginationInfo {
    margin: 0 $px12;

    font-family: "Roboto-Regular";
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0.0015em;
    color: $neutral-900;
  }

  &__rightArrow {
    transform: rotate(180deg);
  }
}
