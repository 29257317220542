@import "../../../../assets/styles/foundations.scss";

.container {
  height: 100vh;
  display: grid;

  grid-template: minmax($px48, 8.5rem) //new
    minmax(33.0625rem, 66.75rem) //new
    minmax($px48, 8.5rem) / //new
    minmax(6.5rem, 11.75rem) //new
    minmax(72rem, 129.75rem) //new
    minmax(6.875rem, 18.5rem);

  .contentContainer {
    width: 100%;
    height: 100%;
    grid-area: 2/2/3/3;
    // display: flex;
    display: grid;
    grid-template-columns: minmax(44.875rem, 91rem) //new
      minmax(6.875rem, 18.5rem) //new
      20.25rem; //new
    grid-template-rows: 100%;

    &__centeredContainer {
      position: relative;
      grid-area: 1/3/2/4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // height: 100vh;
      // width: 50%;

      &__header {
        position: absolute;
        bottom: calc(50% + 10.55625rem);
        left: 0%;

        font-family: "Roboto-Regular";
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: $neutral-900;
      }
    }

    &__imgContainer {
      grid-area: 1/1/2/2;
      height: 100%;
      display: flex;

      img {
        margin: auto;

        max-width: 100%;
        max-height: 100%;
      }
    }

    .wrapper {
      width: 20.25rem; //new

      & > :first-child {
        margin-bottom: $px24;
      }

      & > :last-child {
        margin-top: $px44;
      }

      &__field {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > :first-child {
          margin-bottom: $px4;
        }

        input {
          font-family: "NotoSans-Regular";
          font-size: 14px;
          line-height: 20px;
          color: $neutral-800;
        }

        position: relative;
        display: flex;
        flex-direction: column;

        &__errorMessage {
          position: absolute;
          display: flex;
          align-items: center;
          top: 82px;

          i {
            display: contents;
          }

          span {
            padding-left: $px4;

            font-family: "Roboto-Regular";
            font-size: 13px;
            line-height: 17px;
            color: $red;
          }
        }

        input {
          height: $px52;
          border: $borderDefault;
          border-radius: $radius;
          outline: none !important;
          padding: $px16 $px14;

          &:focus:not(.error) {
            padding: $px16 $px13;
            border: $borderFocus;
          }
        }

        label {
          font-family: "Roboto-Medium";
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: $neutral-800;
          font-display: fallback;
        }

        .error {
          padding: $px16 $px15;
          border: $borderError;
        }
      }
    }
  }
}
