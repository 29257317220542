@import "../../../../../../../../assets/styles/foundations.scss";

.container {
  display: flex;
  height: $px12;
  width: $px12;
  cursor: pointer;

  &:hover {
    .default {
      background-color: $neutral-700;
    }
  }

  .active,
  .default {
    margin: auto;
    border-radius: 50%;
  }

  .active {
    height: $px10;
    width: $px10;

    background-color: $neutral-700;
    box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
  }

  .default {
    height: $px6;
    width: $px6;

    background-color: $neutral-300;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);

    transition: background-color $transitionS;

    // &:hover {
    //   background-color: $neutral-700;
    // }
  }
}
