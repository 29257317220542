@import "../../../../../assets/styles/foundations.scss";

.contentWrapper {
  width: auto;
  padding: $px32 $px64 $px72;
  overflow-y: overlay;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);

  &__body {
    margin: $px50 0 $px64;

    .container {
      margin-bottom: $px40;

      & :first-child {
        margin-bottom: $px20;
      }

      h2 {
        font-family: "Roboto-Regular";
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: $neutral-900;
      }

      p {
        font-family: "Roboto-Regular";
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.0015em;
        color: $neutral-800;
      }
    }
  }
}
