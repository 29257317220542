@font-face {
  font-family: "Roboto-Medium";
  font-weight: 500;
  src: url("Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("Roboto-Regular.ttf") format("truetype");
}
