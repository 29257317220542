@import "../../../../../../assets/styles/foundations.scss";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bigText {
    max-width: 100%;
    text-align: center;

    font-family: "Roboto-Medium";
    font-weight: 500;
    font-size: calc(7.8425rem + (1vw - 0.4425rem));
    line-height: 168.75px;
    color: $neutral-900;

    animation: $showAnimation;
  }
}

/* font-size transition between (small window and hd) / (HD and full-HD) */
@media screen and (max-width: 2240px) {
  .container__bigText {
    font-size: calc(5.745rem + (1vw - 0.4425rem));
  }
}

@media screen and (max-width: 1643px) {
  .container__bigText {
    font-size: calc(4.08875rem + (1vw - 0.4425rem));
    line-height: 84.38px;
  }
}

/* font-size transition on HD window */
/* @media screen and (max-width: 1920px) {
  .bigText-splitPage {
    font-size: calc(6.286rem + (1vw - 0.452rem));
  }
} */
