@import "../../../../../assets/styles/foundations.scss";

.container {
  padding: $px44 $px39;
  border: $borderDefault;
  border-radius: $radius;

  // font-family: "Roboto-Regular";
  // font-size: 16px;
  // line-height: 22px;

  & > :first-child {
    margin-bottom: $px12;
  }

  & > :nth-child(2) {
    margin-bottom: $px28;
  }

  &__question {
    font-family: "Roboto-Medium";
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.0015em;
    color: $neutral-900;
  }

  &__description {
    font-family: "Roboto-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0015em;
    color: $neutral-800;
  }
}

.errorWrapper {
  height: 0;
  position: relative;

  &__errorMessage {
    position: absolute;
    display: flex;
    align-items: center;
    top: $px5;

    i {
      display: contents;
    }

    span {
      padding-left: $px4;

      font-family: "Roboto-Regular";
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.004em;
      color: $red;
    }
  }
}

.error {
  padding: $px43 $px38;
  border: $borderError;
}
