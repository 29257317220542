@import "../../../../../../../assets/styles/foundations.scss";

.container {
  display: flex;
  position: absolute;
  bottom: $px20;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  & > :not(:last-child) {
    margin-right: $px6;
  }
}
