@import "../../../../assets/styles/foundations.scss";

$paddings: $px64;

.container {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(50, 50, 50, 0.65);
  backdrop-filter: blur(4px);
  z-index: 50;

  &__closeBtn {
    height: $px48;
    width: $px48;

    position: absolute;
    right: $px48;
    top: $px16;

    border-radius: 0.4rem;
    background: rgba(122, 122, 122, 0.4);
    z-index: 100;

    transition: background-color $transitionS;

    &:hover {
      background-color: $neutral-800;
    }
  }

  &__leftArrow,
  &__rightArrow {
    height: calc(100% - #{$paddings} * 2);
    width: 12.75rem;

    position: absolute;
    top: $paddings;

    & > :first-child {
      padding: 0 $px72;
    }

    z-index: 150;
  }

  &__leftArrow {
    left: 0;
  }

  &__rightArrow {
    right: 0;
  }

  &__imgContainer {
    grid-area: 2/1/3/2;
    display: flex;
    height: 100%;
    width: 100%;

    & > :first-child {
      margin: auto;
      height: 100%;
      width: 100%;

      & > :first-child {
        height: 100%;
        width: 100%;
        padding: $paddings 0;

        img {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }
    }
  }

  &__zoomContainer {
    position: absolute;
    display: flex;
    bottom: $px20;
    left: calc(100vw - 50%);
    transform: translateX(-50%);
    background-color: $neutral-700;
    border-radius: $radius;

    & > :first-child {
      border-radius: $px4 0 0 $px4;
    }

    & > :last-child {
      border-radius: 0 $px4 $px4 0;
    }
  }
}
