.illustration {
    // border: 1px solid;;
    // .MuiDialog-paperWidthSm {
    //     min-width: 700px !important;
    //     max-width: 1100px !important;
    // }
    width: 100%;
    max-width: 800px;
    font-weight: normal;
    margin: 0 auto;
    &__container {
        margin: 0 auto;
    }
    &__description {
        text-align: left;
        padding: 41px 80px 0 79px;
    }
    &__title {
        text-align: left;
        margin-bottom: 27px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &__title-task {
        margin-top: 35px;
        margin-bottom: 0;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &__text-description {
        margin-bottom: 0;
        margin-top: 0;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &__text {
        margin-bottom: 0;
        margin-top: 9px;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &__text-task {
        text-align: left;
        margin-bottom: 27px;
        &-paragraph {
            margin-top: 6px;
            margin-bottom: 9px;
            text-align: left;
            font-family: Roboto;
            font-weight: normal;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.87);
        }
    }
    &__img-title {
        margin-top: 0px;
        text-align: left;
        margin-bottom: 11px;
        margin-left: 80px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &__task-description {
        padding: 0 80px;
    }
    &__img {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        width: 100%;
        max-width: 800px;
    }
    &__img-screen {
        width: 100%;
        max-width: 640px;
    }
    &__form {
        width: 100%;
    }
    &__form-container {
        display: flex;
        margin-bottom: 25px;
        flex-direction: column;
        justify-content: center;
    }
    .MuiFormControl-root {
        margin-bottom: 20px !important;
        width: 100%;
        height: 100%;
        //height: 108px;
        //max-height: 108px;
        max-width: 640px;
    }
    &__field-container {
        margin-bottom: 15px;
        margin-top: 22px;
        display: inline-block;
        width: 100%;
        text-align: center;

        .text-field-counter {
            position: relative;
            min-width: 640px;

            .Mui-disabled {
                color: black;
            }

            .counter {
                position: absolute;
                right: calc((100% - 640px)/2 + 17px);
                bottom: 25px;
                color: #757575;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-align: right;

                .-big-counter {
                    color: #C63530;
                }
            }
        }
    }
    &__text-container {
        margin-bottom: 22px;
        margin-top: 9px;
    }
    &__text-list {
        margin-top: 0px;
        margin-bottom: 0;
        font-family: Roboto;
        font-style: normal;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
        &_indent {
            text-indent: 18px;
        } 
    }
    &__modal-img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    &__modal-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9999999999;
        cursor: pointer;
        &:hover {
            transition: all 0.3s ease-in-out;
        }
    }
    &__modal-container {
        overflow: hidden;
        position: relative;
    }
    &__bold {
        font-weight: bolder;
    }
    &__img-container {
        padding-top: 1px;
        max-width: 800px;
        &_0 {
            height: 395px;
        }
        &_1 {
            height: 450px;
        }
        &_2 {
            height: 512px;   
        }
        &_3 {
            height: 230px;
        }
        &_4 {
            height: 353px;   
        }
    }
    &__screen-container{
        margin-top: 15px;
        margin-bottom: 30px;
        max-width: 640px;
        &_1 {
            height: 412px;
        }
        &_2 {
            margin-bottom: 33px;
            height: 330px;
        }
    }
    .MuiFormLabel-root {
        //margin-top: 5px;
        //margin-left: 1px;
    }
    .ReactModalPortal {
        z-index: 10 !important;
    }
    
    .ReactModal__Overlay {
        background-color: rgba(0, 0, 0, 0.43) !important;
        z-index: 10 !important;
    }
    
    .ReactModal__Content {
        margin-right: -47% !important;
        z-index: 10 !important;
    }
    
    .MuiInputLabel-outlined {
        z-index: 0 !important;
    }
    
    .ReactModal__Body--open {
        overflow-y: overlay;
        position: fixed;
    }
}
.cross-svg__container:hover {
    .cross-svg {
        &_path {
            fill: #212121;
        }
    }
}