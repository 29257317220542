@import "../../../../../../assets/styles/foundations.scss";

.container {
  display: flex;
  position: relative;
  height: calc(100vh - 11rem); //new
  width: 100%;
  background-color: $neutral-200;

  &:hover {
    .container__leftArrow,
    .container__rightArrow {
      visibility: visible;
    }
  }

  img {
    cursor: pointer;
    margin: auto;
    max-width: 100%;
    max-height: 100%;

    animation: $showAnimation;
  }

  &__leftArrow {
    left: 0;
  }

  &__rightArrow {
    right: 0;
  }

  &__leftArrow,
  &__rightArrow {
    position: absolute;
    visibility: hidden;
    height: 100%;
    width: 6.25rem;
    z-index: 150;

    & > :first-child {
      padding: 0 $px20;
    }
  }
}
