@import "../../../../../assets/styles/foundations.scss";

.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template:
    5.5rem //new
    1fr
    5.5rem / //new
    minmax($px55, 8rem) //new
    minmax(50.25rem, 115.75rem) //new
    minmax($px55, 8rem); //new

  &__header {
    grid-area: 1/1/2/4;
    margin-left: $px32;
    margin-top: $px32;
  }

  &__body {
    grid-area: 2/2/3/3;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    grid-area: 3/1/4/4;
    margin-left: $px16;
    margin-bottom: $px21;
  }
}
