@import "../../../../../../assets/styles/foundations.scss";

.title {
  margin-bottom: $px20;

  font-family: "Roboto-Medium";
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.0015em;
}

.container {
  margin-bottom: $px32;

  & > label {
    padding: $px4 0;
  }

  label {
    font-family: "Roboto-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0015em;
    color: $neutral-800;
  }
}
