$transitionF: all 100ms ease-out;
$transitionM: all 100ms ease-out;
$transitionS: 250ms ease;

$shadow: 0 $px3 $px10 $px2 rgba(0, 0, 0, 0.1);
$radius: $px4;

$borderDefault: $px1 solid $neutral-300;
$borderFocus: $px2 solid $blue;
$borderError: $px2 solid $red;

// Animation
@keyframes fadeImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

$showAnimation: fadeImage 300ms ease-in forwards;
