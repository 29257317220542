@import "../../../../../../../assets/styles/foundations.scss";

.carouselArrow-left {
  transform: scale(-1, 1);
  left: 0;
}

.carouselArrow-right {
  right: 0;
}

.carouselArrow-left,
.carouselArrow-right {
  display: flex;

  height: 100%;
  width: inherit;

  &:hover {
    i {
      color: $neutral-200;
      text-shadow: 0px 0px 5px #000000;
    }
  }

  i {
    position: absolute;
    display: block;

    top: 50%;
    transform: translateY(-50%);

    color: $neutral-300;
    font-size: 60px;
    text-shadow: 0px 0px 2px #212121;

    transition: color $transitionS, text-shadow $transitionS;
  }
}
