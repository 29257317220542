@import "../../../../../assets/styles/foundations.scss";

.zoom-controller-button {
  height: $px44;
  width: $px44;

  transition: background-color $transitionS;

  &:hover {
    background-color: $neutral-800;
  }

  i {
    margin: auto;
    height: $px24;
    width: $px24;
  }
}

.activeColor {
  path {
    fill: $white;
  }
  rect {
    fill: $white;
  }
}
