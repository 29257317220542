.results {
  width: 810px;
  padding: 0 5px;
  box-sizing: border-box;
  background-color: white;
  transition: all 250ms ease-in-out;
  letter-spacing: 0.15px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.87);

  .results-container {
    width: 100%;
    min-height: 386px;
    margin-top: 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 200ms ease-in-out;
  }
}