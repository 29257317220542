$px1: 0.0625rem;
$px2: 0.125rem;
$px3: 0.1875rem;
$px4: 0.25rem;
$px5: 0.3125rem;
$px6: 0.375rem;
$px7: 0.4375rem;
$px8: 0.5rem;

$px10: 0.625rem;
$px11: 0.6875rem;
$px12: 0.75rem;
$px13: 0.8125rem;
$px14: 0.875rem;
$px15: 0.9375rem;
$px16: 1rem;
$px18: 1.125rem;

$px20: 1.25rem;
$px21: 1.3125rem;
$px22: 1.375rem;
$px24: 1.5rem;
$px26: 1.625rem;
$px27: 1.6875rem;
$px28: 1.75rem;

$px30: 1.875rem;
$px32: 2rem;
$px33: 2.0625rem;
$px34: 2.125rem;
$px36: 2.25rem;
$px38: 2.375rem;
$px39: 2.4375rem;

$px40: 2.5rem;
$px43: 2.6875rem;
$px44: 2.75rem;
$px45: 2.8125rem;
$px48: 3rem;

$px50: 3.125rem;
$px52: 3.25rem;
$px53: 3.3125rem;
$px54: 3.375rem;
$px55: 3.4375rem;
$px56: 3.5rem;

$px60: 3.75rem;
$px61: 3.8125rem;
$px64: 4rem;
$px68: 4.25rem;

$px72: 4.5rem;

$px84: 5.25rem;

$px94: 5.875rem;
$px96: 6rem;

$px0: rem;
