@import "../../../../assets/styles/foundations.scss";

.textField {
  display: block;
  height: $px26;
  width: 100%;
  border: 0;
  border-bottom: $borderDefault;
  resize: none;
  outline: none;
  padding-bottom: $px4;

  font-family: "NotoSans-Regular";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.0025em;
  color: $neutral-800;

  transition: $transitionF;

  &:focus:not(.error) {
    border-bottom: $borderFocus;
  }

  // &:focus {
  //   color: $neutral-5;
  // }
}

.error {
  border-bottom: $borderError;
}
