@import "../../../../assets/styles/foundations.scss";

.header {
  display: flex;
  justify-content: space-between;
  padding: $px32 $px64 0 $px32;
  margin-bottom: $px34;
}

.centredWrapper {
  display: flex;
  justify-content: center;

  padding-bottom: $px72;

  &__container {
    display: flex;
    flex-direction: column;
    width: 50.75rem; //new

    & > :last-child {
      align-self: flex-end;
    }

    h2 {
      font-family: "Roboto-Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: $neutral-900;
    }

    & > p {
      margin-top: $px20;

      font-family: "Roboto-Regular";
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      color: $neutral-800;
    }

    &__body {
      margin-top: $px40;
      margin-bottom: $px64;

      & > :not(:last-child) {
        margin-bottom: $px27;
      }
    }
  }
}
